import React, { useContext, useEffect, useRef, useState } from 'react';

// import i18n from '../../i18n';
// import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
    ACTION_TYPES,
    EventListPageContext,
} from '../../containers/EventListPage/EventListPage';
import i18n from '../../i18n';
import EventIcon from '../EventIcon/EventIcon';
import styles from './EventCategories.module.scss';

const EventCategories = ({ categories }) => {
    const { state, dispatch } = useContext(EventListPageContext);
    const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
    const [isScrolledToStart, setIsScrolledToStart] = useState(true);
    const containerRef = useRef(null);

    const checkScroll = () => {
        const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;

        const isScrolledToTheStart = scrollLeft <= 0;
        setIsScrolledToStart(isScrolledToTheStart);

        const isScrolledAllTheWay = scrollLeft + clientWidth >= scrollWidth - 1;
        setIsScrolledToEnd(isScrolledAllTheWay);
    };

    useEffect(() => {
        const container = containerRef.current;
        container.addEventListener('scroll', checkScroll);

        return () => container.removeEventListener('scroll', checkScroll);
    }, []);

    const fadeClasses = classNames(styles['EventCategories__Fade'], {
        [styles['EventCategories__Fade--Hidden']]: isScrolledToEnd,
    });

    const fadeLeftClasses = classNames(
        styles['EventCategories__Fade'],
        styles['EventCategories__Fade--Left'],
        {
            [styles['EventCategories__Fade--Hidden']]: isScrolledToStart,
        }
    );

    const selectCategory = (category) => {
        // isSsr here is needed because otherwise we will clear the selected categories on filtered page load, e.g. /events/?category=music will trigger this and remove music
        if (state.isSsr) {
            return;
        }

        let excludeCategory = false;

        if (state.selectedCategories.indexOf(category.slug) > -1) {
            excludeCategory = category.slug;
        }

        // dataLayerPush({ event: 'filter', filtercategory: category });
        dispatch({
            type: ACTION_TYPES.CATEGORY_SELECTED,
            excludeCategory,
            category: category,
        });
    };

    const scrollDistance = 520;

    const scrollLeft = () => {
        const container = containerRef.current;
        container.scrollTo({
            left: container.scrollLeft - scrollDistance,
            behavior: 'smooth',
        });
    };

    const scrollRight = () => {
        const container = containerRef.current;
        container.scrollTo({
            left: container.scrollLeft + scrollDistance,
            behavior: 'smooth',
        });
    };

    return (
        <div className={styles['EventCategories']}>
            <div className={styles['EventCategories__OuterContainer']}>
                <h2 className="sr-only">{i18n.t('EventCategories.title')}</h2>
                <div className={fadeLeftClasses} />
                <ul
                    className={styles['EventCategories__CategoryContainer']}
                    ref={containerRef}>
                    {categories.map((category, index) => {
                        const classes = classNames(
                            styles['EventCategories__Category'],
                            {
                                [styles['EventCategories__Category--Active']]:
                                    state.selectedCategories.indexOf(
                                        category.slug
                                    ) > -1,
                            }
                        );
                        return (
                            <li key={index}>
                                <button
                                    key={index}
                                    type="button"
                                    className={classes}
                                    aria-pressed={
                                        state.selectedCategories.indexOf(
                                            category.slug
                                        ) > -1
                                            ? 'true'
                                            : 'false'
                                    }
                                    onClick={() => selectCategory(category)}>
                                    <EventIcon icon={category.icon} />
                                    <span
                                        className={
                                            styles['EventCategories__Text']
                                        }>
                                        {category.title}
                                    </span>
                                </button>
                            </li>
                        );
                    })}
                </ul>
                <div className={fadeClasses} />
            </div>

            <div className={styles['EventCategories__ButtonsContainer']}>
                <button
                    onClick={scrollLeft}
                    aria-disabled={isScrolledToStart}
                    className={classNames(
                        styles['EventCategories__Button'],
                        styles['EventCategories__Button--Prev'],
                        {
                            [styles['EventCategories__Button--Disabled']]:
                                isScrolledToStart,
                        }
                    )}></button>
                <button
                    onClick={scrollRight}
                    aria-disabled={isScrolledToEnd}
                    className={classNames(
                        styles['EventCategories__Button'],
                        styles['EventCategories__Button--Next'],
                        {
                            [styles['EventCategories__Button--Disabled']]:
                                isScrolledToEnd,
                        }
                    )}></button>
            </div>
        </div>
    );
};

EventCategories.propTypes = {};

export default EventCategories;
