import React from 'react';

import PropTypes from 'prop-types';
import styles from './EventSubmission.module.scss';
import Button from '../Button/Button';

const EventSubmission = ({ href = '/', title = '', buttonText = '' }) => (
    <div className={styles['EventSubmission']}>
        <div className={styles['EventSubmission__Grid']}>
            <strong className={styles['EventSubmission__Title']}>
                {title}
            </strong>

            <Button
                type="primary"
                text={buttonText || 'Submit here'}
                href={href}
            />
        </div>
    </div>
);

EventSubmission.propTypes = {
    href: PropTypes.string.isRequired,
    title: PropTypes.string,
    buttonText: PropTypes.string,
};

export default EventSubmission;
