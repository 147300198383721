import React from 'react';

// import i18n from '../../i18n';
// import PropTypes from 'prop-types';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import styles from './EventIcon.module.scss';

const ClubsParties = dynamic(() =>
    import('./icons/clubsparties.svg').then((mod) => mod.ReactComponent)
);
const EatDrink = dynamic(() =>
    import('./icons/eatdrink.svg').then((mod) => mod.ReactComponent)
);
const Exhibitions = dynamic(() =>
    import('./icons/exhibitions.svg').then((mod) => mod.ReactComponent)
);
const Fairs = dynamic(() =>
    import('./icons/fairs.svg').then((mod) => mod.ReactComponent)
);
const Festival = dynamic(() =>
    import('./icons/festival.svg').then((mod) => mod.ReactComponent)
);
const Music = dynamic(() =>
    import('./icons/music.svg').then((mod) => mod.ReactComponent)
);
const HolidayBell = dynamic(() =>
    import('./icons/holidaybell.svg').then((mod) => mod.ReactComponent)
);
const StageFilm = dynamic(() =>
    import('./icons/stagefilm.svg').then((mod) => mod.ReactComponent)
);
const Sports = dynamic(() =>
    import('./icons/sports.svg').then((mod) => mod.ReactComponent)
);
const Binoculars = dynamic(() =>
    import('./icons/binoculars.svg').then((mod) => mod.ReactComponent)
);
const Experiment = dynamic(() =>
    import('./icons/experiment.svg').then((mod) => mod.ReactComponent)
);
const Monitoring = dynamic(() =>
    import('./icons/monitoring.svg').then((mod) => mod.ReactComponent)
);
const Networking = dynamic(() =>
    import('./icons/networking.svg').then((mod) => mod.ReactComponent)
);
const Theatre = dynamic(() =>
    import('./icons/theatre.svg').then((mod) => mod.ReactComponent)
);
const Family = dynamic(() =>
    import('./icons/family.svg').then((mod) => mod.ReactComponent)
);

const mapping = {
    clubsparties: ClubsParties,
    eatdrink: EatDrink,
    exhibitions: Exhibitions,
    fairs: Fairs,
    music: Music,
    holidaybell: HolidayBell,
    stagefilm: StageFilm,
    sports: Sports,
    binoculars: Binoculars,
    experiment: Experiment,
    monitoring: Monitoring,
    networking: Networking,
    theatre: Theatre,
    family: Family,
    festival: Festival,
};

const EventIcon = ({ icon, modifiers = [] }) => {
    const Svg = mapping[icon];

    if (!Svg) {
        return null;
    }

    const classes = classNames(
        styles['EventIcon'],
        modifiers.map((modifier) => styles[`EventIcon--${modifier}`])
    );

    return <Svg className={classes} />;
};

EventIcon.propTypes = {};

export default EventIcon;
